import React from 'react'
import { Link, navigate } from 'gatsby'
import firebase from '../lib/firebase'
import googleLogo from '../images/google-logo.svg'
import monoLogo from '../images/mono-logo.svg'
import Spinner2 from '../components/spinner.js'
import SEO from '../components/seo.js'
import { useLocation } from '@reach/router'
import qs from 'query-string'
import { trackError, parseErrorCode } from '../util'
import { useQueryLanguage } from '../hooks/query-lang'
import LogRocket from 'logrocket'

//@Todo refactor to firebase lib
export default props => {
    let { state } = props.location

    const loc = useLocation()

    useQueryLanguage()

    let redirectTo = '/'
    if (state && state.redirectTo) redirectTo = state.redirectTo

    try {
        const qParams = qs.parse(loc.search)
        if (qParams.redirectTo) {
            redirectTo = qParams.redirectTo
        }
    } catch (err) {
        trackError(err)
    }

    const [loading, setLoading] = React.useState(true)
    const [submitting, setSubmitting] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState('')

    React.useEffect(() => {
        return firebase.auth().onAuthStateChanged(user => {
            //Just redirect back to redirectTo if already logged in
            if (user) {
                try {
                    LogRocket.identify(user.uid, { name: user.displayName, email: user.email })
                } catch (err) {
                    console.warn('ERR setting LR identity', err)
                }
                navigate(redirectTo, { replace: true })
            }
            setLoading(false)
        })
    }, [redirectTo])

    const handleLoginWithGoogle = e => {
        e.preventDefault()

        const provider = new firebase.auth.GoogleAuthProvider()
        //Prevents auto selecting the default account if user already signed in with only single google account
        provider.setCustomParameters({ prompt: 'select_account' })

        firebase
            .auth()
            .signInWithPopup(provider)
            .then(user => {
                try {
                    LogRocket.identify(user.uid, { name: user.displayName, email: user.email })
                } catch (err) {
                    console.warn('ERR setting LR identity', err)
                }
                navigate(redirectTo, { replace: true })
            })
            .catch(err => {
                //@Todo handle error
            })
    }
    const handleInput = e => {
        setError(null)
        if (e.target.name === 'email') setEmail(e.target.value)
        if (e.target.name === 'password') setPassword(e.target.value)
    }

    const isValidForm = () => email !== '' && password !== ''

    const handleEmailLogin = e => {
        setError(null)
        e.preventDefault()
        setSubmitting(true)
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(user => {
                redirectTo && navigate(redirectTo)
            })
            .catch(function (error) {
                setError(parseErrorCode(error.code))
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    if (loading) return <Spinner2 />
    return (
        <div className='fixed inset-0 min-h-screen flex-center bg-gray-50'>
            <SEO title='Login' />
            <div className='max-w-md w-full py-12 px-6'>
                <Link to='/' className='group flex-center text-gray-900'>
                    <img
                        alt='Mono FM'
                        width='150px'
                        height='100%'
                        className='group-hover:text-teal-600 transform duration-150 group-hover:scale-110'
                        src={monoLogo}
                    />
                </Link>
                <p className='mt-6 text-sm leading-5 text-center text-gray-900'>
                    Log in or create your account
                </p>
                <div className='mt-6'>
                    <div className='mb-6'>
                        <button
                            onClick={handleLoginWithGoogle}
                            className='flex justify-center items-center w-full text-center py-2 px-4 border border-gray-300 rounded-md font-medium hover:border-gray-800 focus:shadow-outline focus:outline-none focus:border-gray-400 sm:text-sm hover:bg-white hover:text-gray-900 bg-gray-900 text-white sm:leading-5 transform duration-100 scale-100 active:scale-95'
                        >
                            <img alt='Google' className='w-6 h-6' src={googleLogo} />
                            <span className='ml-2'>Log In with Google</span>
                        </button>
                    </div>
                    <div className='relative'>
                        <div className='absolute inset-0 flex items-center'>
                            <div className='w-full border-t border-gray-300' />
                        </div>
                        <div className='relative flex justify-center sm:text-sm leading-5'>
                            <span className='px-2 bg-gray-50 text-gray-500'>
                                Or continue with Email
                            </span>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleEmailLogin}>
                    <div className='mt-6 rounded-md shadow-sm'>
                        <div>
                            <input
                                aria-label='Email address'
                                name='email'
                                type='email'
                                value={email}
                                onChange={handleInput}
                                required=''
                                className='border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5'
                                placeholder='Email address'
                            />
                        </div>
                        <div className='-mt-px relative'>
                            <input
                                aria-label='Password'
                                name='password'
                                type='password'
                                value={password}
                                onChange={handleInput}
                                required=''
                                className='border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5'
                                placeholder='Password'
                            />
                            <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5'>
                                <a href='/password-reset' className='text-gray-900 underline'>
                                    Forgot?
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <button
                            className={`${
                                submitting || !isValidForm()
                                    ? 'opacity-50 pointer-events-none'
                                    : 'hover:bg-gray-600'
                            } 
                            ${error ? 'shake' : ''}
relative block w-full py-2 px-3 border border-transparent rounded-md text-white font-semibold bg-gray-800 hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95
                                    `}
                            type='submit'
                        >
                            <span className='absolute left-0 inset-y pl-3'>
                                <svg
                                    className='h-5 w-5 text-gray-500'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                            </span>

                            <span className={`${submitting ? 'opacity-0' : ''} `}>Log in</span>
                            <span
                                className={`${
                                    submitting ? 'opacity-100' : 'opacity-0'
                                } absolute inset-0 flex items-center justify-center pointer-events-none `}
                            >
                                <svg className='h-8 w-8 spin' viewBox='0 0 24 24'>
                                    <path
                                        className='text-gray-600'
                                        fill='currentColor'
                                        d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                    />
                                    <path
                                        className='text-gray-400'
                                        fill='currentColor'
                                        d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                    />
                                </svg>
                            </span>
                        </button>
                        <div className='flex-center my-2'>
                            <a
                                className='text-sm border-b hover:border-black text-center'
                                href='/signup'
                            >
                                No account? Signup Instead
                            </a>
                        </div>
                    </div>
                    <p
                        className={`my-2 py-2 px-4 duration-75 ${
                            error ? 'opacity-1000' : 'opacity-0'
                        } transition-opacity text-center text-red-700 rounded bg-red-100 font-medium text-sm`}
                    >
                        {error}
                    </p>
                </form>
                <div className='mt-12 space-x-4 text-sm flex-center'>
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='mailto:support@mono.fm'
                        className='hover:text-black border-b border-transparent hover:border-black'
                    >
                        Help
                    </a>
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://mono.fm/terms'
                        className='hover:text-black border-b border-transparent hover:border-black'
                    >
                        Terms
                    </a>
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://mono.fm/privacy'
                        className='hover:text-black border-b border-transparent hover:border-black'
                    >
                        Privacy
                    </a>
                </div>
            </div>
        </div>
    )
}
